import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { IntlProvider } from 'react-intl'
import initLocalisation from './assets/util/localisation/initLocalisation';

import { StoreProvider } from './Store';

const container = document.getElementById('root');
const localisation = initLocalisation()
console.log('-----Entory---');
render(
  <IntlProvider {...localisation}>
  <StoreProvider>
    <App></App>
  </StoreProvider>,
  </IntlProvider>,
  container
);
