import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { useStyles } from '../styles';
import { Store } from '../Store';
import axios from "axios";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { 
    USER_SIGNUP,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAIL,
    USER_SIGNIN,
    USER_SIGNIN_SUCCESS,
    USER_SIGNIN_FAIL,
} from '../constants';
import { useHistory } from "react-router-dom"; 
import config, { getParameterByName, merchantCode } from '../util';

const SignUp = (props) => {
    const styles = useStyles();
    const { state, dispatch } = useContext(Store);
    const [showPass, setShowPass] = useState(false);
    const [SignedIn, setSignedIn] = useState(true);

    const [singUpFirstName, setSignUpFirstName] = useState("");
    const [signUpLastName, setSignUpLastName] = useState("");
    const [signUpEmail, setSignUpEmail] = useState("");
    const [singUpNumber, setSignUpNumber] = useState("");
    const [signUpAddress, setSignUpAddress] = useState("");
    const [singUpPassword, setSignUpPassword] = useState("");

    const [signInEmail, setSignInEmail] = useState("");
    const [singInPassword, setSignInPassword] = useState("");
    let loggedUser = sessionStorage.getItem("customerInfo");
     const history = useHistory();
     console.log(loggedUser);
     if(loggedUser){
       props.setOpenSign(false);
    }
    
    

    const userSignUp = async () => {
        dispatch({ type: USER_SIGNUP });
        try {
            const res = await axios.post(`${config.authapi}/customer/register`, {
                firstName: singUpFirstName,
                lastName: signUpLastName,
                email: signUpEmail,
                phone: singUpNumber,
                password: singUpPassword,
                referenceDetails: "",
                userId: state.userData.userInfo ? state.userData.userInfo.merchantCode : "",
                address: signUpAddress,
                isEmailVerified: false,
                isPhoneVerified: false,
                merchantCode: state.userData.userInfo ? state.userData.userInfo.merchantCode : "",
            });

            if (res.data) {
                //setSignedIn(true);
                 sessionStorage.setItem("customerInfo", JSON.stringify(res.data));
                dispatch({
                    type: USER_SIGNUP_SUCCESS,
                    payload: res.data,
                });
              return  history.push("/");
            }
        } catch (err) {
            dispatch({
                type: USER_SIGNUP_FAIL,
                payload: err.message,
            });
            return;
        }
    }

    const userSignIn = async () => {
        dispatch({ type: USER_SIGNIN });
        try {
            const { data } = await axios.post(`${config.authapi}/customer/authenticate`, { email: signInEmail, password: singInPassword });

            if (data) {
                //props.setSignDialog(false);
                sessionStorage.setItem("customerInfo", JSON.stringify(data));
                return dispatch({
                    type: USER_SIGNIN_SUCCESS,
                    payload: data,
                });
                 //history.push("/");
            }
        } catch (error) {
            document.getElementById("error").innerHTML = "Username and password incorrect!";
            dispatch({
                type: USER_SIGNIN_FAIL,
                payload: error.message,
            });
            return;
        }
    }

    return (
        <Box className={styles.root} style={{ backgroundColor: state.selectedBgColor, height: "auto" }}>
            {SignedIn ? (
                <div>
                    
                    <h2 align='center'>Sign In</h2>
                    <span id='error' style={{ color: "red" }}></span><br />
                    <span>
                        <label>Email or Phone number <span style={{ color: "red" }}>*</span></label><br />
                        <input type='text' className='login_input' autoComplete="off" placeholder='Email or Phone number' onChange={(e) => setSignInEmail(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Password <span style={{ color: "red" }}>*</span></label><br />
                        <span>
                            <input type='password' style={{ marginBottom: "8px" }} autoComplete="off" placeholder='Password' id="pass" className='login_input' onChange={(e) => setSignInPassword(e.target.value)} />
                            <span style={{ position: "absolute", right: "68px", cursor: "pointer" }}>
                                {showPass ?
                                    <VisibilityOffOutlinedIcon onClick={() => {
                                        setShowPass(false);
                                        document.getElementById("pass").type = "password";
                                    }} />
                                    :
                                    <VisibilityOutlinedIcon onClick={() => {
                                        setShowPass(true);
                                        document.getElementById("pass").type = "text";
                                    }} />
                                }
                            </span>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px", width: "95%" }}>
                                <span style={{ cursor: "pointer", color: "blue", fontSize: '15px' }}>ForgotPassword?</span>
                                <span>New user? please,
                                    <span onClick={() => setSignedIn(false)} style={{ cursor: "pointer", color: "rgb(13, 150, 155)", fontSize: '18px' }}>Sign Up</span>
                                </span>
                            </div>
                        </span>
                    </span>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
                        <button onClick={userSignIn} className='s_btn' style={{ backgroundColor: "blue", width: "160px", height: "50px", borderRadius: "50px" }}>Sign In</button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "4px" }}>
                        <span style={{ cursor: "pointer" }} onClick={() => props.handleDialog()}>Guest Account</span>
                    </div>
                </div>
            ) : (
                <div>
                    {/* Render sign-up form */}
                    <h2 align='center'>Sign Up</h2>
                    <span>
                        <label>First Name <span style={{ color: "red" }}>*</span></label><br />
                        <input type='text' className='userInput_log' onChange={(e) => setSignUpFirstName(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Last Name <span style={{ color: "red" }}>*</span></label><br />
                        <input type='text' className='userInput_log' onChange={(e) => setSignUpLastName(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Phone No <span style={{ color: "red" }}>*</span></label><br />
                        <input type='text' className='userInput_log' onChange={(e) => setSignUpNumber(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Address <span style={{ color: "red" }}>*</span></label><br />
                        <input type='text' className='userInput_log' onChange={(e) => setSignUpAddress(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Email <span style={{ color: "red" }}>*</span></label><br />
                        <input type='email' className='userInput_log' autoComplete="off" placeholder='Email' onChange={(e) => setSignUpEmail(e.target.value)} />
                    </span><br />
                    <span>
                        <label>Password <span style={{ color: "red" }}>*</span></label><br />
                        <span>
                            <input type='password' id='userPass' autoComplete="off" className='userInput_log' onChange={(e) => setSignUpPassword(e.target.value)} />
                            <span style={{ position: "absolute", right: "68px", cursor: "pointer" }}>
                                {showPass ?
                                    <VisibilityOffOutlinedIcon onClick={() => {
                                        setShowPass(false);
                                        document.getElementById("userPass").type = "password";
                                    }} />
                                    :
                                    <VisibilityOutlinedIcon onClick={() => {
                                        setShowPass(true);
                                        document.getElementById("userPass").type = "text";
                                    }} />
                                }
                            </span>
                        </span>
                    </span><br />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
                        <button onClick={() => setSignedIn(true)} className='c_btn' style={{ backgroundColor: "#fff", color: "#0c1c02", fontSize: "18px" }}>Back</button>
                        <button className='s_btn' style={{ backgroundColor: "blue" }} onClick={userSignUp}>Sign Up</button>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default SignUp;
